
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as action_45feedUy7lS20MW1Meta } from "/workspace/src/pages/app/action-feed.vue?macro=true";
import { default as analytics_45fitness_45journalAgIgNfRrPWMeta } from "/workspace/src/pages/app/analytics-fitness-journal.vue?macro=true";
import { default as analytics_45muscle_45recoveryfd5sca4SDoMeta } from "/workspace/src/pages/app/analytics-muscle-recovery.vue?macro=true";
import { default as analyticsj45DTUShHCMeta } from "/workspace/src/pages/app/analytics.vue?macro=true";
import { default as communityKIod9dig7TMeta } from "/workspace/src/pages/app/community.vue?macro=true";
import { default as elementsozqi9Y8sgSMeta } from "/workspace/src/pages/app/elements.vue?macro=true";
import { default as payment_45check13Z8NB2ZQ4Meta } from "/workspace/src/pages/app/payment-check.vue?macro=true";
import { default as paymentYpgPm70rOXMeta } from "/workspace/src/pages/app/payment.vue?macro=true";
import { default as plan_45detailsLPCsRU6O3aMeta } from "/workspace/src/pages/app/plan-details.vue?macro=true";
import { default as plan_45scheduleKjWjI9qPADMeta } from "/workspace/src/pages/app/plan-schedule.vue?macro=true";
import { default as planUkI4NQiAWpMeta } from "/workspace/src/pages/app/plan.vue?macro=true";
import { default as settings_45plan5SjPBskYjLMeta } from "/workspace/src/pages/app/settings-plan.vue?macro=true";
import { default as settings_45profileyi6AU2YTwoMeta } from "/workspace/src/pages/app/settings-profile.vue?macro=true";
import { default as settings_45subscriptionGHZSfsceJrMeta } from "/workspace/src/pages/app/settings-subscription.vue?macro=true";
import { default as settingsc7DZqbLvYGMeta } from "/workspace/src/pages/app/settings.vue?macro=true";
import { default as previewid3FqU5jsVMeta } from "/workspace/src/pages/app/training/preview.vue?macro=true";
import { default as statistics6J8LGD0wZ1Meta } from "/workspace/src/pages/app/training/statistics.vue?macro=true";
import { default as workoutnp3KYNfOCNMeta } from "/workspace/src/pages/app/training/workout.vue?macro=true";
import { default as activeGWDCaaYLt9Meta } from "/workspace/src/pages/app/workout/active.vue?macro=true";
import { default as preparationutv6dJvxh8Meta } from "/workspace/src/pages/app/workout/preparation.vue?macro=true";
import { default as presetuqwDxlEWbVMeta } from "/workspace/src/pages/app/workout/preset.vue?macro=true";
import { default as wizardk1jHMmS2V1Meta } from "/workspace/src/pages/app/workout/wizard.vue?macro=true";
import { default as apph4H2RQxrtFMeta } from "/workspace/src/pages/app.vue?macro=true";
import { default as forgot_45passwordFomoTLyxSpMeta } from "/workspace/src/pages/auth/forgot-password.vue?macro=true";
import { default as loginNKwifwsPUuMeta } from "/workspace/src/pages/auth/login.vue?macro=true";
import { default as welcomeouIvhO1ZHfMeta } from "/workspace/src/pages/auth/welcome.vue?macro=true";
import { default as exercisesrTL3to56goMeta } from "/workspace/src/pages/exercises.vue?macro=true";
import { default as indexQ0gwGo0vtVMeta } from "/workspace/src/pages/index.vue?macro=true";
import { default as activity_45levellC2hEWNo3SMeta } from "/workspace/src/pages/onboarding/female/activity-level.vue?macro=true";
import { default as are_45your_45mothertamkieRB5iMeta } from "/workspace/src/pages/onboarding/female/are-your-mother.vue?macro=true";
import { default as birthday83AsRfOSKFMeta } from "/workspace/src/pages/onboarding/female/birthday.vue?macro=true";
import { default as body_45shape_45infoYWZgnnxvXXMeta } from "/workspace/src/pages/onboarding/female/body-shape-info.vue?macro=true";
import { default as can_45you_45go_45to_45gymu9P7xPzUB8Meta } from "/workspace/src/pages/onboarding/female/can-you-go-to-gym.vue?macro=true";
import { default as chapter_45body_45dataw8gIYt6ZZVMeta } from "/workspace/src/pages/onboarding/female/chapter-body-data.vue?macro=true";
import { default as chapter_45fitness_45analysisBvRrQCkaj8Meta } from "/workspace/src/pages/onboarding/female/chapter-fitness-analysis.vue?macro=true";
import { default as chapter_45goalzObVQxdpTzMeta } from "/workspace/src/pages/onboarding/female/chapter-goal.vue?macro=true";
import { default as checkoutZSywSpd2ZfMeta } from "/workspace/src/pages/onboarding/female/checkout.vue?macro=true";
import { default as child_45ageuUjcimSDpSMeta } from "/workspace/src/pages/onboarding/female/child-age.vue?macro=true";
import { default as current_45body_45shape4UonqDzukQMeta } from "/workspace/src/pages/onboarding/female/current-body-shape.vue?macro=true";
import { default as current_45weightFHiFBAh6D4Meta } from "/workspace/src/pages/onboarding/female/current-weight.vue?macro=true";
import { default as easily_45give_45upiBgsnpnhuCMeta } from "/workspace/src/pages/onboarding/female/easily-give-up.vue?macro=true";
import { default as faster_45resultAyHuRE4G46Meta } from "/workspace/src/pages/onboarding/female/faster-result.vue?macro=true";
import { default as feel_45unsatisfiedJqdX6YFA7RMeta } from "/workspace/src/pages/onboarding/female/feel-unsatisfied.vue?macro=true";
import { default as fitness_45levelrRaKN0KcnTMeta } from "/workspace/src/pages/onboarding/female/fitness-level.vue?macro=true";
import { default as flexibilityzQjbPioKr1Meta } from "/workspace/src/pages/onboarding/female/flexibility.vue?macro=true";
import { default as focus_45area4KgQhRKdYNMeta } from "/workspace/src/pages/onboarding/female/focus-area.vue?macro=true";
import { default as generate_45planDLA0TnosvHMeta } from "/workspace/src/pages/onboarding/female/generate-plan.vue?macro=true";
import { default as goal_45infoyy3pQS9Om6Meta } from "/workspace/src/pages/onboarding/female/goal-info.vue?macro=true";
import { default as height4JN71MJ622Meta } from "/workspace/src/pages/onboarding/female/height.vue?macro=true";
import { default as how_45often_45workoutHi7eIM6saXMeta } from "/workspace/src/pages/onboarding/female/how-often-workout.vue?macro=true";
import { default as how_45will_45celebraten9WiORV8eDMeta } from "/workspace/src/pages/onboarding/female/how-will-celebrate.vue?macro=true";
import { default as indexPFDrXYzKipMeta } from "/workspace/src/pages/onboarding/female/index.vue?macro=true";
import { default as main_45goalsNnLzBgNnlMeta } from "/workspace/src/pages/onboarding/female/main-goal.vue?macro=true";
import { default as motivation_45photo_451wW7RGGxm8uMeta } from "/workspace/src/pages/onboarding/female/motivation-photo-1.vue?macro=true";
import { default as motivation_45photo_452IkVPyONYGwMeta } from "/workspace/src/pages/onboarding/female/motivation-photo-2.vue?macro=true";
import { default as motivation_45photo_453AnhKpCopjtMeta } from "/workspace/src/pages/onboarding/female/motivation-photo-3.vue?macro=true";
import { default as motivationMWjj0MMLwEMeta } from "/workspace/src/pages/onboarding/female/motivation.vue?macro=true";
import { default as new_45datexP1Er375vHMeta } from "/workspace/src/pages/onboarding/female/new-date.vue?macro=true";
import { default as paymentpp2Qo9oAH7Meta } from "/workspace/src/pages/onboarding/female/payment.vue?macro=true";
import { default as personal_45planY7hp8eC6YpMeta } from "/workspace/src/pages/onboarding/female/personal-plan.vue?macro=true";
import { default as pick_45up_45suitable_45workoutspZXgiFEnEJMeta } from "/workspace/src/pages/onboarding/female/pick-up-suitable-workouts.vue?macro=true";
import { default as pre_45focus_45areaKfwAaHK9GkMeta } from "/workspace/src/pages/onboarding/female/pre-focus-area.vue?macro=true";
import { default as prefer_45workouto8yOKf81sLMeta } from "/workspace/src/pages/onboarding/female/prefer-workout.vue?macro=true";
import { default as preferred_45workout_45levelT3Z1u7v6cwMeta } from "/workspace/src/pages/onboarding/female/preferred-workout-level.vue?macro=true";
import { default as preferred_45workout_45typeah6eSRNvhiMeta } from "/workspace/src/pages/onboarding/female/preferred-workout-type.vue?macro=true";
import { default as registrationX7gcB4SL4QMeta } from "/workspace/src/pages/onboarding/female/registration.vue?macro=true";
import { default as social_45proof4EUNEKOOwTMeta } from "/workspace/src/pages/onboarding/female/social-proof.vue?macro=true";
import { default as special_45event_45infoLRl1FPrmNAMeta } from "/workspace/src/pages/onboarding/female/special-event-info.vue?macro=true";
import { default as special_45eventObvrhRxiFzMeta } from "/workspace/src/pages/onboarding/female/special-event.vue?macro=true";
import { default as stopping_45your_45goal_45infoJtdwqS264WMeta } from "/workspace/src/pages/onboarding/female/stopping-your-goal-info.vue?macro=true";
import { default as stopping_45your_45goalb54LiyaQ3vMeta } from "/workspace/src/pages/onboarding/female/stopping-your-goal.vue?macro=true";
import { default as subscribe_45for_45early_45birdswbsCvo4CCuMeta } from "/workspace/src/pages/onboarding/female/subscribe-for-early-birds.vue?macro=true";
import { default as suffered_45any_45discomfortTEZctBs58BMeta } from "/workspace/src/pages/onboarding/female/suffered-any-discomfort.vue?macro=true";
import { default as target_45body_45shapecpEPNSx3PZMeta } from "/workspace/src/pages/onboarding/female/target-body-shape.vue?macro=true";
import { default as target_45datetaIXLcAyukMeta } from "/workspace/src/pages/onboarding/female/target-date.vue?macro=true";
import { default as target_45weightA6W9tr1qGaMeta } from "/workspace/src/pages/onboarding/female/target-weight.vue?macro=true";
import { default as we_45can_45make_45ithGMUyyuBAlMeta } from "/workspace/src/pages/onboarding/female/we-can-make-it.vue?macro=true";
import { default as your_45namezrPPzX5F31Meta } from "/workspace/src/pages/onboarding/female/your-name.vue?macro=true";
import { default as gendertDZrVaeCigMeta } from "/workspace/src/pages/onboarding/gender.vue?macro=true";
import { default as indexzUZtLY6q8rMeta } from "/workspace/src/pages/onboarding/index.vue?macro=true";
import { default as indexhKsGQnMe8mMeta } from "/workspace/src/pages/onboarding/male/index.vue?macro=true";
import { default as onboardinggj5kGv55qpMeta } from "/workspace/src/pages/onboarding.vue?macro=true";
export default [
  {
    name: "app",
    path: "/app",
    meta: apph4H2RQxrtFMeta || {},
    component: () => import("/workspace/src/pages/app.vue"),
    children: [
  {
    name: "app-action-feed",
    path: "action-feed",
    component: () => import("/workspace/src/pages/app/action-feed.vue")
  },
  {
    name: "app-analytics-fitness-journal",
    path: "analytics-fitness-journal",
    component: () => import("/workspace/src/pages/app/analytics-fitness-journal.vue")
  },
  {
    name: "app-analytics-muscle-recovery",
    path: "analytics-muscle-recovery",
    component: () => import("/workspace/src/pages/app/analytics-muscle-recovery.vue")
  },
  {
    name: "app-analytics",
    path: "analytics",
    component: () => import("/workspace/src/pages/app/analytics.vue")
  },
  {
    name: "app-community",
    path: "community",
    component: () => import("/workspace/src/pages/app/community.vue")
  },
  {
    name: "app-elements",
    path: "elements",
    component: () => import("/workspace/src/pages/app/elements.vue")
  },
  {
    name: "app-payment-check",
    path: "payment-check",
    component: () => import("/workspace/src/pages/app/payment-check.vue")
  },
  {
    name: "app-payment",
    path: "payment",
    component: () => import("/workspace/src/pages/app/payment.vue")
  },
  {
    name: "app-plan-details",
    path: "plan-details",
    component: () => import("/workspace/src/pages/app/plan-details.vue")
  },
  {
    name: "app-plan-schedule",
    path: "plan-schedule",
    component: () => import("/workspace/src/pages/app/plan-schedule.vue")
  },
  {
    name: "app-plan",
    path: "plan",
    component: () => import("/workspace/src/pages/app/plan.vue")
  },
  {
    name: "app-settings-plan",
    path: "settings-plan",
    component: () => import("/workspace/src/pages/app/settings-plan.vue")
  },
  {
    name: "app-settings-profile",
    path: "settings-profile",
    component: () => import("/workspace/src/pages/app/settings-profile.vue")
  },
  {
    name: "app-settings-subscription",
    path: "settings-subscription",
    component: () => import("/workspace/src/pages/app/settings-subscription.vue")
  },
  {
    name: "app-settings",
    path: "settings",
    component: () => import("/workspace/src/pages/app/settings.vue")
  },
  {
    name: "app-training-preview",
    path: "training/preview",
    component: () => import("/workspace/src/pages/app/training/preview.vue")
  },
  {
    name: "app-training-statistics",
    path: "training/statistics",
    component: () => import("/workspace/src/pages/app/training/statistics.vue")
  },
  {
    name: "app-training-workout",
    path: "training/workout",
    component: () => import("/workspace/src/pages/app/training/workout.vue")
  },
  {
    name: "app-workout-active",
    path: "workout/active",
    component: () => import("/workspace/src/pages/app/workout/active.vue")
  },
  {
    name: "app-workout-preparation",
    path: "workout/preparation",
    component: () => import("/workspace/src/pages/app/workout/preparation.vue")
  },
  {
    name: "app-workout-preset",
    path: "workout/preset",
    component: () => import("/workspace/src/pages/app/workout/preset.vue")
  },
  {
    name: "app-workout-wizard",
    path: "workout/wizard",
    component: () => import("/workspace/src/pages/app/workout/wizard.vue")
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/workspace/src/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/workspace/src/pages/auth/login.vue")
  },
  {
    name: "auth-welcome",
    path: "/auth/welcome",
    component: () => import("/workspace/src/pages/auth/welcome.vue")
  },
  {
    name: "exercises",
    path: "/exercises",
    component: () => import("/workspace/src/pages/exercises.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQ0gwGo0vtVMeta || {},
    component: () => import("/workspace/src/pages/index.vue")
  },
  {
    name: onboardinggj5kGv55qpMeta?.name,
    path: "/onboarding",
    component: () => import("/workspace/src/pages/onboarding.vue"),
    children: [
  {
    name: "onboarding-female-activity-level",
    path: "female/activity-level",
    component: () => import("/workspace/src/pages/onboarding/female/activity-level.vue")
  },
  {
    name: "onboarding-female-are-your-mother",
    path: "female/are-your-mother",
    component: () => import("/workspace/src/pages/onboarding/female/are-your-mother.vue")
  },
  {
    name: "onboarding-female-birthday",
    path: "female/birthday",
    component: () => import("/workspace/src/pages/onboarding/female/birthday.vue")
  },
  {
    name: "onboarding-female-body-shape-info",
    path: "female/body-shape-info",
    component: () => import("/workspace/src/pages/onboarding/female/body-shape-info.vue")
  },
  {
    name: "onboarding-female-can-you-go-to-gym",
    path: "female/can-you-go-to-gym",
    component: () => import("/workspace/src/pages/onboarding/female/can-you-go-to-gym.vue")
  },
  {
    name: "onboarding-female-chapter-body-data",
    path: "female/chapter-body-data",
    component: () => import("/workspace/src/pages/onboarding/female/chapter-body-data.vue")
  },
  {
    name: "onboarding-female-chapter-fitness-analysis",
    path: "female/chapter-fitness-analysis",
    component: () => import("/workspace/src/pages/onboarding/female/chapter-fitness-analysis.vue")
  },
  {
    name: "onboarding-female-chapter-goal",
    path: "female/chapter-goal",
    component: () => import("/workspace/src/pages/onboarding/female/chapter-goal.vue")
  },
  {
    name: "onboarding-female-checkout",
    path: "female/checkout",
    component: () => import("/workspace/src/pages/onboarding/female/checkout.vue")
  },
  {
    name: "onboarding-female-child-age",
    path: "female/child-age",
    component: () => import("/workspace/src/pages/onboarding/female/child-age.vue")
  },
  {
    name: "onboarding-female-current-body-shape",
    path: "female/current-body-shape",
    component: () => import("/workspace/src/pages/onboarding/female/current-body-shape.vue")
  },
  {
    name: "onboarding-female-current-weight",
    path: "female/current-weight",
    component: () => import("/workspace/src/pages/onboarding/female/current-weight.vue")
  },
  {
    name: "onboarding-female-easily-give-up",
    path: "female/easily-give-up",
    component: () => import("/workspace/src/pages/onboarding/female/easily-give-up.vue")
  },
  {
    name: "onboarding-female-faster-result",
    path: "female/faster-result",
    component: () => import("/workspace/src/pages/onboarding/female/faster-result.vue")
  },
  {
    name: "onboarding-female-feel-unsatisfied",
    path: "female/feel-unsatisfied",
    component: () => import("/workspace/src/pages/onboarding/female/feel-unsatisfied.vue")
  },
  {
    name: "onboarding-female-fitness-level",
    path: "female/fitness-level",
    component: () => import("/workspace/src/pages/onboarding/female/fitness-level.vue")
  },
  {
    name: "onboarding-female-flexibility",
    path: "female/flexibility",
    component: () => import("/workspace/src/pages/onboarding/female/flexibility.vue")
  },
  {
    name: "onboarding-female-focus-area",
    path: "female/focus-area",
    component: () => import("/workspace/src/pages/onboarding/female/focus-area.vue")
  },
  {
    name: "onboarding-female-generate-plan",
    path: "female/generate-plan",
    component: () => import("/workspace/src/pages/onboarding/female/generate-plan.vue")
  },
  {
    name: "onboarding-female-goal-info",
    path: "female/goal-info",
    component: () => import("/workspace/src/pages/onboarding/female/goal-info.vue")
  },
  {
    name: "onboarding-female-height",
    path: "female/height",
    component: () => import("/workspace/src/pages/onboarding/female/height.vue")
  },
  {
    name: "onboarding-female-how-often-workout",
    path: "female/how-often-workout",
    component: () => import("/workspace/src/pages/onboarding/female/how-often-workout.vue")
  },
  {
    name: "onboarding-female-how-will-celebrate",
    path: "female/how-will-celebrate",
    component: () => import("/workspace/src/pages/onboarding/female/how-will-celebrate.vue")
  },
  {
    name: "onboarding-female",
    path: "female",
    component: () => import("/workspace/src/pages/onboarding/female/index.vue")
  },
  {
    name: "onboarding-female-main-goal",
    path: "female/main-goal",
    component: () => import("/workspace/src/pages/onboarding/female/main-goal.vue")
  },
  {
    name: "onboarding-female-motivation-photo-1",
    path: "female/motivation-photo-1",
    component: () => import("/workspace/src/pages/onboarding/female/motivation-photo-1.vue")
  },
  {
    name: "onboarding-female-motivation-photo-2",
    path: "female/motivation-photo-2",
    component: () => import("/workspace/src/pages/onboarding/female/motivation-photo-2.vue")
  },
  {
    name: "onboarding-female-motivation-photo-3",
    path: "female/motivation-photo-3",
    component: () => import("/workspace/src/pages/onboarding/female/motivation-photo-3.vue")
  },
  {
    name: "onboarding-female-motivation",
    path: "female/motivation",
    component: () => import("/workspace/src/pages/onboarding/female/motivation.vue")
  },
  {
    name: "onboarding-female-new-date",
    path: "female/new-date",
    component: () => import("/workspace/src/pages/onboarding/female/new-date.vue")
  },
  {
    name: "onboarding-female-payment",
    path: "female/payment",
    component: () => import("/workspace/src/pages/onboarding/female/payment.vue")
  },
  {
    name: "onboarding-female-personal-plan",
    path: "female/personal-plan",
    component: () => import("/workspace/src/pages/onboarding/female/personal-plan.vue")
  },
  {
    name: "onboarding-female-pick-up-suitable-workouts",
    path: "female/pick-up-suitable-workouts",
    component: () => import("/workspace/src/pages/onboarding/female/pick-up-suitable-workouts.vue")
  },
  {
    name: "onboarding-female-pre-focus-area",
    path: "female/pre-focus-area",
    component: () => import("/workspace/src/pages/onboarding/female/pre-focus-area.vue")
  },
  {
    name: "onboarding-female-prefer-workout",
    path: "female/prefer-workout",
    component: () => import("/workspace/src/pages/onboarding/female/prefer-workout.vue")
  },
  {
    name: "onboarding-female-preferred-workout-level",
    path: "female/preferred-workout-level",
    component: () => import("/workspace/src/pages/onboarding/female/preferred-workout-level.vue")
  },
  {
    name: "onboarding-female-preferred-workout-type",
    path: "female/preferred-workout-type",
    component: () => import("/workspace/src/pages/onboarding/female/preferred-workout-type.vue")
  },
  {
    name: "onboarding-female-registration",
    path: "female/registration",
    component: () => import("/workspace/src/pages/onboarding/female/registration.vue")
  },
  {
    name: "onboarding-female-social-proof",
    path: "female/social-proof",
    component: () => import("/workspace/src/pages/onboarding/female/social-proof.vue")
  },
  {
    name: "onboarding-female-special-event-info",
    path: "female/special-event-info",
    component: () => import("/workspace/src/pages/onboarding/female/special-event-info.vue")
  },
  {
    name: "onboarding-female-special-event",
    path: "female/special-event",
    component: () => import("/workspace/src/pages/onboarding/female/special-event.vue")
  },
  {
    name: "onboarding-female-stopping-your-goal-info",
    path: "female/stopping-your-goal-info",
    component: () => import("/workspace/src/pages/onboarding/female/stopping-your-goal-info.vue")
  },
  {
    name: "onboarding-female-stopping-your-goal",
    path: "female/stopping-your-goal",
    component: () => import("/workspace/src/pages/onboarding/female/stopping-your-goal.vue")
  },
  {
    name: "onboarding-female-subscribe-for-early-birds",
    path: "female/subscribe-for-early-birds",
    component: () => import("/workspace/src/pages/onboarding/female/subscribe-for-early-birds.vue")
  },
  {
    name: "onboarding-female-suffered-any-discomfort",
    path: "female/suffered-any-discomfort",
    component: () => import("/workspace/src/pages/onboarding/female/suffered-any-discomfort.vue")
  },
  {
    name: "onboarding-female-target-body-shape",
    path: "female/target-body-shape",
    component: () => import("/workspace/src/pages/onboarding/female/target-body-shape.vue")
  },
  {
    name: "onboarding-female-target-date",
    path: "female/target-date",
    component: () => import("/workspace/src/pages/onboarding/female/target-date.vue")
  },
  {
    name: "onboarding-female-target-weight",
    path: "female/target-weight",
    component: () => import("/workspace/src/pages/onboarding/female/target-weight.vue")
  },
  {
    name: "onboarding-female-we-can-make-it",
    path: "female/we-can-make-it",
    component: () => import("/workspace/src/pages/onboarding/female/we-can-make-it.vue")
  },
  {
    name: "onboarding-female-your-name",
    path: "female/your-name",
    component: () => import("/workspace/src/pages/onboarding/female/your-name.vue")
  },
  {
    name: "onboarding-gender",
    path: "gender",
    component: () => import("/workspace/src/pages/onboarding/gender.vue")
  },
  {
    name: "onboarding",
    path: "",
    component: () => import("/workspace/src/pages/onboarding/index.vue")
  },
  {
    name: "onboarding-male",
    path: "male",
    component: () => import("/workspace/src/pages/onboarding/male/index.vue")
  }
]
  }
]